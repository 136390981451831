@font-face {
  font-family: 'TheSansLF';
  src: url('/fonts/TheSansLF.woff2') format('woff2'), url('/fonts/TheSansLF.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'TheSansLF';
  src: url('/fonts/TheSansLF_Light.woff2') format('woff2'),
    url('/fonts/TheSansLF_Light.woff') format('woff');
  font-weight: 200;
}
@font-face {
  font-family: 'TheSansLF';
  src: url('/fonts/TheSansLF_Bold.woff2') format('woff2'),
    url('/fonts/TheSansLF_Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'TheSansLF';
  src: url('/fonts/TheSansSC_Bold.woff2') format('woff2'),
    url('/fonts/TheSansSC_Bold.woff') format('woff');
  font-weight: 900;
}
